import React from "react";
import Footer from "../../components/Footer/Footer";
import HeroImage from "../../components/HeroImage/HeroImage";
import Navbar from "../../components/Navbar/Navbar";
import Card from "../../components/Card/Card";
import { projectsData } from "../../assets/data/projectsData";
import "./Projects.css";
import { Link, useNavigate } from "react-router-dom";
import Btn1 from "../../components/Btn1/Btn1";

const Projects = () => {
  const navigate = useNavigate();

  const openInActiveTab = (url) => {
    window.open(url, "_self");
  };

  return (
    <div className="fadeIn">
      <Navbar />
      <HeroImage heading="PROJELER" text="" />
      <div
        style={{
          marginTop: "50px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignContent: "center",
          gap: 50,
        }}
      >
        {projectsData.map((project, index) => {
          return (
            <div
              onClick={() => {
                //   to={`/projects/${project.id}`}
                navigate("/projects/" + project?.id);
              }}
              key={index}
            >
              <Card project={project} />
            </div>
          );
        })}
      </div>
      <div className="home-center-div" style={{ marginTop: "5vh" }}>
        <Btn1
          value="Daha Fazla..."
          onClick={() =>
            openInActiveTab("https://www.astronomtamer.com/category/projeler/")
          }
        />
      </div>
      <Footer />
    </div>
  );
};

export default Projects;

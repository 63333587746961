import "./Card.css";

import React from "react";

const Card = (props) => {
  const { images, title, content } = props.project;
  return (
    <div>
      <div className="card">
        <div className="card-header">
          <img src={images[0]} alt="rover" />
        </div>
        <div className="card-body">
          <h4 style={{ color: "#424242" }}>{title}</h4>
          <br />
          <p>{content.substring(0, 250)}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;

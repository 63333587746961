import React from "react";
import Particles from "react-tsparticles";

const ParticleComponent = () => {
  return (
    <Particles
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        zIndex: -1,
      }}
      id="tsparticles"
      options={{
        background: {
          color: {
            //  value: theme.palette.secondary.main,
          },
        },
        fpsLimit: 60,
        interactivity: {
          events: {},
        },
        particles: {
          color: {
            value: "#dddddd",
          },

          collisions: {
            enable: false,
          },
          rotate: {
            value: 0,
            direction: "random",
            animation: {
              speed: 2,
              enable: true
            }
          },
          move: {
            direction: "none",
            enable: true,
            outMode: "out",
            random: false,
            speed: 3,
            straight: false,
          },

          number: {
            value: 5,
          },
          opacity: {
            value: 1,
          },
          shape: {
            type: "image",
            image: [
              {
                src: "https://pngimg.com/uploads/astronaut/astronaut_PNG64.png",
              },
              {
                src: "https://pngimg.com/uploads/astronaut/astronaut_PNG42.png",
              },
            ],
          },
          size: {
            random: true,
            value: 200,
            minimumValue: 50,
          },
        },
        detectRetina: true,
      }}
    />
  );
};

export default ParticleComponent;

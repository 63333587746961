import React from "react";
import "./Resume.css";
const Resume = () => {
  return (
    <div style={{ color: "white", margin: 30 }}>
      <h1>Özgeçmiş</h1>
      <br />
      <ul className="resume-ul">
        <li>Alanı: Astronom</li>
        <li>Deneyim: 15 Yıl</li>
      </ul>
      <p>
        <br />
        <u></u>
        <strong>DOĞUM TARİHİ</strong>
        &nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        :&nbsp;&nbsp; 15/10/1977
      </p>
      <p>
        <strong>DOĞUM YERİ</strong>
        &nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        :&nbsp;&nbsp; Edirne &nbsp; &nbsp; &nbsp;&nbsp;
      </p>

      <h2 style={{ marginTop: 25, marginBottom: 10 }}>Deneyim</h2>
      <ul className="resume-ul">
        <li>
          2002-2003 : 50. Yıl İlköğretim Okulu/ Edirne-Kıyık (İngilizce
          Öğretmenliği)
        </li>
        <li>
          1997-1999 : Park Tekstil Fabrikası- Elektrik Bakımcılığı/ Edirne
        </li>
        <li>
          2011- : Optronik Optik ve Elektronik Cihazlar Ticaret ve Sanayi
          Ltd.Şti.
        </li>
      </ul>
      <h2 style={{ marginTop: 25, marginBottom: 10 }}>Eğitim</h2>
      <ul className="resume-ul">
        <li>
          <em>
            Trakya Üniversitesi Meslek Yüksek Okulu- Elektrik Bölümü/
            Edirne(1995-1997)
          </em>
        </li>
        <li>
          <em>
            Trakya Üniversitesi Fen-Edebiyat Fakültesi Fizik Bölümü/
            Edirne(1997-2001)
          </em>
        </li>
        <li>
          <em>
            Çanakkale Onsekiz Mart Üniversitesi Astrofizik Yüksek
            Lisans)(2003-2006)
          </em>
        </li>
      </ul>
      <p>
        <em>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          <strong>Tez Konusu :</strong>{" "}
        </em>
        <strong>
          <em>²</em>
        </strong>
        <strong>
          <em> Teleskop Kubbelerinin Otomasyonu ve Ulupınar </em>
        </strong>
      </p>
      <p>
        <strong>
          <em>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            Gözlemevindeki Teleskop kubbelerine uygulanması
          </em>
        </strong>
        <strong>
          <em>²</em>
        </strong>
      </p>
      <ul className="resume-ul">
        <li>
          <em>
            Çanakkale Onsekiz Mart Üniversitesi Fizik Doktora Programı(DEVAM)
          </em>
        </li>
      </ul>

      <h2 style={{ marginTop: 25, marginBottom: 10 }}>Yetenekler</h2>
      <div style={{ display: "flex", justifyContent: "start", gap: 50 }}>
        <div>
          <strong>
            <em>Bilgisayar :</em>
          </strong>
          <ul className="resume-ul">
            <li>
              <em>Office 2003(Word, Excel, Powerpoint, Frontpage)</em>
            </li>
            <li>
              <em>Autocad Programlama Dili</em>
            </li>
            <li>
              <em>Çeşitli Analiz İndirgeme Programları.</em>
            </li>
            <li>
              <em>Web tasarımı.</em>
            </li>
            <li>
              <em>C programlama</em>
            </li>
          </ul>
        </div>

        <div>
          <strong>
            <em>İngilizce :</em>
          </strong>
          <ul className="resume-ul">
            <li>
              Okuma: <em> İYİ</em>
            </li>
            <li>
              Yazma: <em> İYİ</em>
            </li>
            <li>
              Anlama: <em> İYİ</em>
            </li>
          </ul>
        </div>
      </div>

      <h2 style={{ marginTop: 25, marginBottom: 10 }}>Etkinlikler</h2>
      <ul className="resume-ul">
        <li>
          <em>Jeoloji Mühendisliği FİZ104 Fizik II dersi uygulaması. </em>
        </li>
        <li>
          <em>Fizik Laboratuar derslerinde deney gözetmenliği.</em>
        </li>
        <li>
          <em>
            Eğitim Fakültesi son sınıf öğrencilerini bilgilendirmeyi amaçlayan
            bir proje için Astronomi bilgileri içeren CD hazırlandı. Hazırlanan
            CD kapsamı: Genel astronomi bilgileri, Güneş Sistemi, gezegenler,
            kuyruklu yıldızlar, gök taşları, yıldızlar (Nötron yıldızları,
            Pulsarlar, Karadelikler), yıldız kümeleri, galaksiler ve evrenin
            genel yapısı ve evrimi. Evrende yasam, UFO’lar,&nbsp; Astroloji,
            Falcılık gibi konular ve öğrencilerin soracağı sorular ve yanıtları.
          </em>
        </li>
        <li>
          <em>
            Ulusal Atronomi Toplantısı 2012 Malatya İnönü Üniversitesi
            27-Ağustos-2012 Astronom olarak katılmak ve seminer vermek.
          </em>
        </li>
        <li>
          <em>
            Sınav Koleji gözlemevine temel Astronomi eğitimi ve&nbsp; astronom
            danışmanlığı..
          </em>
        </li>
        <li>
          <em>
            Gaziantep Türkcell Gezegenevi Planetaryum oparatörüne temel
            Astronomi eğitimi.
          </em>
        </li>
        <li>
          <em>
            Tuzla Deniz Harp Okulu Planetaryum oparatörüne temel Astronomi
            eğitimi.
          </em>
        </li>
        <li>
          <em>
            İzmit Cebir Koleji&nbsp; Koleji gözlemevine temel Astronomi eğitimi
            ve astronom danışmanı. .
          </em>
        </li>
        <li>
          <em>
            Ankara Sınav Koleji gözlemevine temel Astronomi eğitimi ve astronom
            danışmanlığı.
          </em>
        </li>
        <li>
          <em>
            &nbsp;01 Haziran 2006–30 Haziran 2006 tarihleri arasında
            Çanakkale’mizde görev yapan hekimlerimize yönelik Astronomi Eğitim
            Çalışmalarında görev.&nbsp;{" "}
          </em>
        </li>
        <li>
          <em>
            106T051 Proje nolu TÜBİTAK Araştırma Projesinde yardımcı personel
            olarak 01.07.2006 tarihi itibariyle iki(2)yıl görev.
          </em>
        </li>
        <li>
          <em>
            108T714 Proje nolu TÜBİTAK Araştırma Projesinde Yardımcı personel
            olarak 09.05.2009 tarihi itibariyle iki(2) yıl görev.
          </em>
        </li>
        <li>
          <em>Gözlemevindeki meteoroloji istasyonu ve konsolu tamir edildi.</em>
        </li>
        <li>
          <em>
            Gözlemevinde yapılan 2.Yaz Kampında aktif görev (Ders anlatma ve
            seminer).
          </em>
        </li>
        <li>
          <em>
            40cm’lik ve 30cm’lik teleskopların kumandaları tamir edilip joystick
            modeli uygulandı.{" "}
          </em>
        </li>
        <li>
          <em>
            29 Mart 2006 Tam Güneş Tutulması için düzenlenen gezide sorumlu
            görevli olarak çalışma.
          </em>
        </li>
        <li>
          <em>
            ÇOMÜ Gözlemevindeki teleskoplarda aktif olarak Fotometrik ve CCD
            gözlemleri yapıp veri indirgeme.{" "}
          </em>
        </li>
        <li>
          <em>
            Temmuz-Ağustos 2006 dönemlerinde TÜBİTAK Ulusal Gözlemevinde 5
            günlük gözlemlerde fiilen katılmak ve oradaki teknik sorumlularla
            kullanılan teçhizatların tamiri ve bakımı hakkında bilgi
            alış-verişinde bulunmak.
          </em>
        </li>
        <li>
          <em>
            06 Haziran 2012 tarihinde Gölhisar’da gerçekleşen “Venüs
            Geçişi”Optronik Ltd. Şti. Temsil etmek.
          </em>
        </li>
        <li>
          <em>
            15 Temmuz 2012 Trabzon Bilim ve Sanat Merkezi tarafından düzenlenen
            “ Doğa ve Astronomi” adlı kampında uzman astronom olarak katılmak.{" "}
          </em>
        </li>
        <li>
          <em>
            TÜBİTAK destekli 112B193 nolu 28 Ağustos-01 Eylül 2012 tarihleirnde
            yapılan “Yasemin Karakaya Bilim ve Sanat Merkezi Kızılcahamam Doğa
            Eğitimi” projesinde Astronom Eğitmeni olarak görev almak.{" "}
          </em>
        </li>
        <li>
          <em>
            TÜBİTAK destekli 4004 kapsamında 115B352 nolu Mayıs 2015
            tarihlerinde yapılan “Ilgaz Dağı Milli Parkında Mikro Alemden Makro
            Aleme Doğayı Keşfederek Öğreniyorum” projesinde Astronom Eğitmeni
            olarak görev almak.
          </em>
        </li>
        <li>
          <em>
            15 Ekim 2012 Erzincan Bilim ve Sanat Merkezi “Erzincan’da Yıldızlar
            Daha Yakın” adlı astronomi eğitim ve gece gözlemi etkinliğinde uzman
            astronom olarak katılım.
          </em>
        </li>
        <li>
          <em>
            17 Ekim 2012 Rize Bilim veSanat Merkezinin düzenlemiş olduğu
            “Jüpiter’in Doğuşu” adlı astronomi eğitimi ve gece gözlemi
            etkinliğinde uzman astronom olarak katılım.
          </em>
        </li>
        <li>
          <em>
            24-26 Aralık 2012 Sivas Bilim ve Sanat Merkezinin düzenlemiş olduğu
            “enim Gökyüzüm” projesi kapsamında uzay ve astronomi hakkında
            astronom olarak konferans verip gece gökyüzü gözlem etkinliğinde
            yapmak.
          </em>
        </li>
        <li>
          <em>
            13-15 Aralık 2015 tarihinde Antalya’da düzenlenen “Okul
            Gözlemevleri” Sempozyumuna katılıp “Okkullarımızda gözlemeveri” adlı
            sunum yapmak.
          </em>
        </li>
        <li>
          <em>
            Eylül 2011 tarihinde Kapadokya Ejderha Meteor yağmurunda astronomi
            eğitmeni olarak.
          </em>
        </li>
        <li>
          <em>
            Kars Kafkas Üniversitesi Eğitim Fakültesi Fen Bilgisi Eğitimi Ana
            Bilim dalı başkanlığı 29 Nisan 2015 tarihinde düzenlenen “ Evrene
            Açılan Kapı” seminerinde çağrılı konuşmacı olarak katılmak.
          </em>
        </li>
        <li>
          <em>
            Kırıkkale Üniversitesi Eğitim Fakültesinin 06 Aralık 2014 tarihinde
            düzenlemiş olduğu “Astronomi ve Uzay Bilimlerinde Gelişmeler ve Gök
            cisimlerinin gösterimi” semineri için konuşmacı olarak katılım.{" "}
          </em>
        </li>
        <li>
          <em>
            Samsun Ezgiler Kolejinde 20.11.2015 tarihnde düzenlenen “Astronomi
            Aşkı” adlı etkinlikte konuşmacı olarak katılmak.
          </em>
        </li>
        <li>
          <em>
            Trabzon Bilim ve Sanat Merkezinde 19.11.2015 tarihinde
            düzenlenen”Temel Astronomi Seminerinde konuşmacı olarak katılmak.
          </em>
        </li>
        <li>
          <em>
            Rize Bilim ve Sanat Merkezinde18.11.2015 tarihinde düzenlenen
            “Astronom Tamer AKIN ile söyleşi ve gözlem Etkinliğinde” yer almak.
          </em>
        </li>
        <li>
          <em>
            Kocaeli Bilim ve Sanat Merkezinde 27.11.2015 tarihinde düzenlenen
            “Astronomi ve Gökyüzü” adlı etkinlikte konuşmacı olarak katılmak.{" "}
          </em>
        </li>
        <li>
          <em>
            05-06 Aralık 2015 tarihinde düzenlenen Astronomi Öğretmenleri
            Semineri(AÖS-V) “Işık Kirliliği” konulu topantıda konuşmacı ve
            eğitimci olarak katılmak.
          </em>
        </li>
      </ul>
      <h2 style={{ marginTop: 25, marginBottom: 10 }}>Akademik</h2>
      <ul className="resume-ul">
        <li>
          <em>İlk ve Orta Öğretimde Fen ve Fizik Öğretimi,</em>
          <em>
            {" "}
            Çanakkale Onsekiz Mart Üniversitesi, ÇANAKKALE, 11–12 Haziran 2004,{" "}
          </em>
        </li>
        <li>
          <em>Be Stars and Data Analysis of Close Binary Stars,</em>
          <em> Çanakkale Onsekiz Mart Üniversitesi, ÇANAKKALE, 1 Ekim 2004 </em>
        </li>
        <li>
          <em>
            Amatör Astronomi Toplantısı, İstanbul Kültür Üniversitesi, 25-26
            Haziran 2005
          </em>
        </li>
        <li>
          <em>2005 Dünya Fizik Yılı Çanakkale Savaşında Ölen Fizikçi</em>{" "}
          <em>“Henry Moseley’i Anma Çalıştayı” ,</em>
          <em>
            {" "}
            Çanakkale Onsekiz Mart Üniversitesi, ÇANAKKALE, &nbsp;29 Eylül 2005{" "}
          </em>
        </li>
        <li>
          <em>
            Radyasyon ve Çevre Sempozyumu–2006, Çanakkale 18 Mart Üniversitesi,
            29 Haziran 2006.
          </em>
        </li>
        <li>
          <em>
            “Alternative Solutions in Astrophysics”, Çanakkale 18 Mart
            Üniversitesi ÇANAKKALE, 3-7 Temmuz 2006.
          </em>
        </li>
        <li>
          <em>
            Ulusal Atronomi Toplantısı 2012 Malatya İnönü Üniversitesi
            27-Ağustos-2012
          </em>
        </li>
        <li>
          <em>
            Kayseri Erciyes Üniversitesinin düzenlemiş olduğu “5. Gözlem
            Şenliği” 24-25 Kasım 2012.
          </em>
        </li>
        <li>
          <em>
            29-30 Ocak 2015 Antalya’da düzenlenen “Özel Okullar Toplantısı”.
          </em>
        </li>
        <li>
          06.2015 Isparta Süleyman Demirel Üniversitesi “Gökyüzü Farkındalığı 2”
          TÜBİTAK projesi toplantısı.
        </li>
        <li>08.2016 Astronomi Öğretmenleri Semineri -4” toplantısı.</li>
      </ul>
    </div>
  );
};

export default Resume;

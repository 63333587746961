import React, { useEffect } from "react";
import Particles from "react-tsparticles";

import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import ParticleComponent from "../../components/ParticleComponent";

import Video from "../../components/Video/Video";
import "./Home.css";

const Home = () => {
  return (
    <div className="fadeIn">
      <Navbar />
      <ParticleComponent />
      <Video />
      <Footer />
    </div>
  );
};

export default Home;

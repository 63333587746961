import React from "react";
import Footer from "../../components/Footer/Footer";
import HeroImage from "../../components/HeroImage/HeroImage";
import Navbar from "../../components/Navbar/Navbar";
import Resume from "../../htmlData/Resume";
import "./About.css";

const About = () => {
  return (
    <div className="fadeIn">
      <Navbar />
      <HeroImage heading="HAKKIMDA" text="Ben Kimim?" />
      <Resume />
      <Footer />
    </div>
  );
};

export default About;

import React from "react";
import Footer from "../../components/Footer/Footer";
import HeroImage from "../../components/HeroImage/HeroImage";
import Navbar from "../../components/Navbar/Navbar";
import "./Contact.css";

const Contact = () => {
  return (
    <div className="fadeIn">
      <Navbar />
      <HeroImage heading="İLETİŞİM" text="Bana ulaşın..." />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          flexDirection: "column",
          marginTop: 50,
        }}
      >
        <h3 style={{color:"white"}}>astronomtamerakin@gmail.com</h3>
        <br />
        <a href="https://api.whatsapp.com/send?phone=+905462809588">
          Whatsapp +90 546 280 9588
        </a>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;

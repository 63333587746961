import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import HeroImage from "../../components/HeroImage/HeroImage";
import Navbar from "../../components/Navbar/Navbar";
import Card from "../../components/Card/Card";
import { projectsData } from "../../assets/data/projectsData";
import "./ProjectByID.css";
import { useParams } from "react-router";

const ProjectByID = () => {
  const { id } = useParams();
  const [project, setProject] = useState();
  useEffect(() => {
    const tmp = projectsData.find((x) => x.id == id);
    setProject(tmp);
    console.log(tmp);
  }, [id]);

  return (
    <div className="fadeIn">
      <Navbar />
      <HeroImage heading="PROJE" text={project?.title} />
      <div
        style={{
          margin: "50px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "start",
          alignContent: "center",
          gap: 50,
        }}
      >
        <h2
          style={{
            color: "white",
          }}
        >
          {project?.title}
        </h2>
        <p style={{ letterSpacing: "2px" }}>{project?.content}</p>
        {project?.images.map((image, index) => {
          return (
            <img
              style={{ objectFit: "cover", width: "500px" }}
              src={image}
              key={index}
            />
          );
        })}
      </div>
      <Footer />
    </div>
  );
};

export default ProjectByID;

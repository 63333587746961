import React from "react";
import "./Footer.css";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaMailBulk,
  FaPhone,
  FaSearchLocation,
  FaTwitter,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";

const Footer = () => {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div className="footer">
      <div className="footer-container">
        <div className="left">
          <div className="location">
            <FaSearchLocation
              size={20}
              style={{ color: "#ffffff", marginRight: "2rem" }}
            />
            <div>
              <p>Cankaya</p>
              <h4>Ankara, TR</h4>
            </div>
          </div>
          <div className="phone">
            <h4>
              <a href="tel: +905462809588">
                <FaPhone
                  size={20}
                  style={{ color: "#ffffff", marginRight: "2rem" }}
                />
                0546 280 9588
              </a>
            </h4>
          </div>
          <div className="email">
            <h4>
              <FaMailBulk
                size={20}
                style={{ color: "#ffffff", marginRight: "2rem" }}
              />
              astronomtamerakin@gmail.com
            </h4>
          </div>
        </div>
        <div className="right">
          <h4>Info</h4>
          <p>Astronomi seminerleri ve gözlem etkinlikleri</p>
          <div className="social" style={{ zIndex: 999 }}>
            <FaWhatsapp
              onClick={() =>
                openInNewTab(
                  "https://api.whatsapp.com/send?phone=+905462809588"
                )
              }
              size={30}
              style={{
                cursor: "pointer",
                color: "#ffffff",
                marginRight: "1rem",
              }}
            />
            <FaFacebook
              onClick={() => openInNewTab("https://www.facebook.com/tamer.akn")}
              size={30}
              style={{
                cursor: "pointer",
                color: "#ffffff",
                marginRight: "1rem",
              }}
            />
            <FaInstagram
              onClick={() =>
                openInNewTab("https://www.instagram.com/tamer.akn/")
              }
              size={30}
              style={{
                cursor: "pointer",
                color: "#ffffff",
                marginRight: "1rem",
              }}
            />

            <FaYoutube
              onClick={() =>
                openInNewTab(
                  "https://www.youtube.com/channel/UCl_2wuwtNghDxwL5O_hkSSg"
                )
              }
              size={30}
              style={{
                cursor: "pointer",
                color: "#ffffff",
                marginRight: "1rem",
              }}
            />
            <FaLinkedin
              onClick={() => openInNewTab("", "_blank")}
              size={30}
              style={{
                cursor: "pointer",
                color: "#ffffff",
                marginRight: "1rem",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import "./Btn1.css";
const FlipButton = (props) => {
  return (
    <div onClick={props.onClick}>
      <div class="custom-btn1">{props?.value}</div>
    </div>
  );
};

export default FlipButton;

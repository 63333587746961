import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import img from "../../assets/tamer.jpg";
import "./Navbar.css";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 100) {
      setColor(true);
    } else {
      setColor(false);
    }
  };
  const openInActiveTab = (url) => {
    window.open(url, "_self");
  };

  window.addEventListener("scroll", changeColor);

  return (
    <div className={color ? "header header-bg" : "header"}>
      {/* <Link to='/'><h1 className='header-name'>Astronom Tamer AKIN</h1></Link>  */}
      <img
        src={img}
        style={{ borderRadius: "50%", width: 50, cursor: "pointer" }}
        onClick={() => openInActiveTab("https://astronomtamerakin.com")}
      />
      <ul className={click ? "nav-menu active" : "nav-menu"}>
        <li>
          <Link
            to="/"
            onClick={() =>
              openInActiveTab(
                "https://www.astronomtamer.com/tag/egitim-materyali/"
              )
            }
          >
            Home
          </Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
        <li>
          <Link to="/projects">Projects</Link>
        </li>
        <li>
          <a href="https://www.astronomtamer.com/home/">Blog</a>
        </li>
      </ul>
      <div className="hamburger" onClick={handleClick}>
        {click ? (
          <FaTimes size={20} style={{ color: "#fff" }} />
        ) : (
          <FaBars size={20} style={{ color: "#fff" }} />
        )}
      </div>
    </div>
  );
};

export default Navbar;
